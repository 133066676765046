function Project({title, image, description, year, github}){
    year = year.substring(0,4)
    return(
        <a href={github}>
        <div className="proj">
            <div className="proj-details">
           
                <img src={image} className="proj-pic" alt=""/>
                
                <div className='proj-head'>
                    <h3 className="proj-title">{title} - {year}</h3>
                    <p>{description}</p>
                </div>
                <div className="learn-more">
                </div>
            </div>
        </div>
        </a>
    )
}

export default Project;