import FadeInSection from "./components/FadeIn"

function Skills() {
    return (
        <FadeInSection>
            <div className="section">
                <h2 className="heading">Skills</h2>
                <div className='skillset'>
                    <h3>Front End</h3>
                    <p>React.js</p><p>HTML5</p><p>CSS3</p><p>JavaScript</p>
                </div>
                <div className='skillset'>
                    <h3>Back End</h3>
                    <p>Node.js</p><p>Express.js</p><p>Java</p><p>Python</p><p>SpringBoot</p><p>C#</p><p>C++</p><p>.NET</p>
                </div>
                <div className='skillset'>
                    <h3>Databases and Containers</h3>
                    <p>SQL</p><p>Postgres</p><p>MongoDB</p><p>Docker</p>
                </div>
                <div className='skillset'>
                    <h3>Conceptual Understanding</h3>
                    <p>Algorithms</p><p>Data Structures</p><p>Compiler Optimization</p><p>Complexity Analysis</p><p>Computer Architecture</p>
                </div>
            </div>
        </FadeInSection>
    )
}

export default Skills