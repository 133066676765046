import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Project from './components/Project'
import FadeInSection from './components/FadeIn';
import myprojects from './portfolio.projects.json' // <-- this is also part of the json edit


function Projects(){
    const [projects, setProjects] = useState([])
    
    



//   useEffect(() => {
//     axios.get('http://localhost:7070/').then(
//       response => {
//         console.log(response.data)
//         setData(response.data);
//       }
//     ).catch(error => {
//       console.error(error);
//     })
//   }, [])

    function compare( a, b ) {
    if ( a.dateStarted < b.dateStarted ){
      return 1;
    }
    if ( a.dateStarted > b.dateStarted ){
      return -1;
    }
    return 0;
  }
//   THIS IS THE REAL CODE YOU SHOULD USE FOR DEPLOYMENT
  
//     useEffect(() => {
//             axios.get('http://16.171.144.152:8080/api/projects')
//             .then((projects) => {
//                 console.log(projects.data)
//                 setProjects(projects.data.sort(compare))
//             })
            
//     }, [])



//     return (
//       <FadeInSection>
//           <div className="section">
//               <h2 className="heading">Projects</h2>
//               {projects.map((proj) => {
//                 return proj && <Project key={proj.id} title={proj.title} image={proj.image} description={proj.desc} year={proj.dateStarted} github={proj.github} />
//               })}
//           </div>
//       </FadeInSection>
//     )
// }

// export default Projects;


// TEMP CODE FOR WHEN JSON FILE HOLD DATA

return (
        <FadeInSection>
            <div className="section">
                <h2 className="heading">Projects</h2>
                {myprojects.projects.map((proj) => {
                  return proj && <Project key={proj.id} title={proj.title} image={proj.image} description={proj.desc} year={proj.dateStarted.$date} github={proj.github} />
                })}
            </div>
        </FadeInSection>
      )
  }

  export default Projects;