import { NavLink } from "react-router-dom";

function Menu(){
    return (
    <nav className='menu'>
      <ul>
        {/* <li>
            <NavLink to="/" className="menu-link">Home</NavLink>
        </li>
        <li>
            <NavLink to="/projects" href='#projects' className="menu-link">Projects</NavLink>
        </li>
        {/* <li>
            <NavLink to="/blogs" className="menu-link">Blog</NavLink>
        </li> }
        <li>
            <NavLink to="/contact" href='#contact' className="menu-link">Get In Touch</NavLink>
        </li> */}

        <li><a href='#about' className='menu-link'>About</a></li>
        <li><a href='#skills' className='menu-link'>Skills</a></li>
        <li><a href='#projects' className='menu-link'>Projects</a></li>
        <li><a href='#contact' className='menu-link'>Contact</a></li>
      </ul>
    </nav>)

}

export default Menu;