import FadeInSection from "./components/FadeIn";

function About(){
    return (
        <FadeInSection>
            <div className="section">
                <h2 className="heading">About</h2>
                <p>Originally from Silicon Valley, I had early exposure to the world of programming and tech. When I decided to persue a degree in Computer Science, it was no surprise to those who knew me. What was a surprise was just how much I loved it!</p> 
                <p>Throughout school I worked tirelessly in my classes, but also endeavored to teach myself languages and frameworks outside the classroom to boost my knowledge and enhance my skillset. Programming was addicting, because I could build anything I liked, how I liked it. The creativity involved was immensely gratifying and the intricacies of software design and computability theory were intectually stimulating.</p>
                <p>As a recent graduate, I'm excited to implement and contribute all I have learned in the past few years, and also seeking to gain valuable experience to continue developing my abilities.</p>
                <p>When I'm not by my laptop, you can find me at home with my husband and newborn son or sitting outside gazing at the stars.</p>
            </div>
        </FadeInSection>
    )
}

export default About;