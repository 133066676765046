
import '../App.css';

import React from 'react'
import { Routes, Route } from 'react-router-dom';
import Home from './Home';
import Projects from './Projects';
import About from './About'
import ContactForm from './Contact';
import Skills from './Skills'

function App() {
    
   return (


    <div className="body" style={{background: `linear-gradient(135deg, #000000, #003a96, #000000)`}}>
            <div className='left-side'><Home /></div>
            
            <div className='right-side'>
                <a id='about'><About /></a><br/>
                <a id='skills'><Skills /></a>
                <a id='projects'><Projects /></a><br/>
                {/* <Blogs /> <br/> */}
                <a id='contact'><ContactForm /></a>

            {/* <Routes>
                <Route path="/"/>
                <Route path="/projects" element={<Projects />} />
                <Route path="/blogs" element={<Blogs />} /> 
                <Route path="/blog/:id" element={<BlogView />} />
                <Route path="/contact" element={<ContactForm />} />
            </Routes> */}
            </div>

    </div>)

}

export default App;
