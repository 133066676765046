import Menu from "./components/Menu";


function Home(){


    return(
        <div className='homepage'>
            <div>
                <div className='logo'></div>
                
                <h2 className='desc'>I like to build software and learn new things</h2>
            </div>
            <Menu />
        </div>
    )
}

export default Home;