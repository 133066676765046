import axios from "axios";
import React, {useState} from 'react'
import FadeInSection from "./components/FadeIn";

function ContactForm(){
    const [formData, setFormData] = useState({name: "",email: "",message: ""});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = (event) => {
        event.preventDefault();
        axios.post("http://localhost:7070/api/contact",
            {
                name: formData.name,
                email: formData.email,
                dateContacted: Date.now(),
                message: formData.message,
                replied: false
            }
        )
        setFormData(() => ({name: "", email: "", message: ""}))
       
    };

  return (
    <FadeInSection>
      <div className='contact section'>
      <h3>Get in touch! I'd love to hear from you 	&#128513; </h3>
      <form onSubmit={handleSubmit}>
        <label htmlFor="name">Name</label>
        <input type="text" id="name" name="name" value={formData.name} onChange={handleChange}/><br/>

        <label htmlFor="email">Email</label>
        <input type="text" id="email" name="email" value={formData.email} onChange={handleChange}/><br/>

        <label htmlFor="message">Message</label>
        <textarea type="text" id="message" name="message" value={formData.message} onChange={handleChange}/><br/>

        <button type="submit">Send me a message</button>
      </form>
      </div>
    </FadeInSection>
  );
}

export default ContactForm;